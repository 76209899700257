@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.list {
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
}

.list::-webkit-scrollbar {
  width: 12px;
}

.list::-webkit-scrollbar-track {
  background: var(--colorScrollBackground);
}

.list::-webkit-scrollbar-thumb {
  background-color: var(--colorGrey200);
  border-radius: 10px;
  border: 3px solid var(--colorScrollBackground);
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  font-size: 13px;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  span {
    label {
      span {
        font-size: 14px;
      }
    }
  }
}

.groupContainer {
  padding-bottom: 8px;
}

.groupLabelButton {
  padding: 0;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: start;
  gap: 8px;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }

  svg {
    fill: var(--spxGreen);
  }
}

.selectDeselectAll {
  label {
    margin: 8px 0;
    span {
      font-size: 14px;
      color: var(--spxGreenDarkest);
      &:hover,
      &:focus,
      &:checked {
        color: var(--spxGreenLight);
      }
    }
  }
}

.openIcon {
  transform: rotate(-90deg);
}
